import React from "react";
import { Dropdown } from "antd";
import styles from "./SelectWithCheckbox.module.css"
import { SelectArrowIcon } from "../icons-component/IconsComponent";

const SelectWithCheckbox = ({ setIsOpen, isOpen, items, label, overlayClassName, isActive }) => {
	return (
		<Dropdown
			onOpenChange={(e) => setIsOpen((prev) => !prev)}
			open={isOpen}
			overlayClassName={overlayClassName && overlayClassName}
			trigger={["click"]}
			menu={{
				items
			}}
		>
			<div className={`${styles.select_with_checkbox} ${(isOpen || isActive) ? styles.active : ""}`}>
				<p className={styles.select_title}>{label}</p>
				<SelectArrowIcon
					className={`${(isOpen || isActive) ? styles.icon_arrow_active : styles.icon_arrow}`}
					style={{ transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)" }}
				/>
			</div>

		</Dropdown>
	)

}

export default SelectWithCheckbox
