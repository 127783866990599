import React from 'react'
import PopularCategories from '../main-page/components/popular-categories/popular-categories'
import HelpBlock from '../help-block/HelpBlock'
import FooterComponent from './FooterComponent'
import styles from "./index.module.css";

const FooterGroup = () => {
	return (
		<>
			<div className="container">
				<div className={styles.popular_categories}>
					<h3 className={`${styles.title} fs16 fw500 lh20 main-gray`}>Popular Categories</h3>
					<PopularCategories />
				</div>
			</div>
			<HelpBlock />
			<FooterComponent />
		</>
	)
}

export default FooterGroup