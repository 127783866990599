import React from "react";
import styles from "./index.module.css";
import FooterColumn from "./FooterColumn";
import { footerColumns } from "./utils";

const FooterComponent = () => {
	let { contactUs, forTeam, products, companyFocus, products_next } = footerColumns || {};
	return (
		<footer className={styles.footer}>
			<div className={styles.footer_container}>
				<div>
					<button
						// onClick={() => history.push("/")} 
						className={styles.logo_button}
					>
						<img src="/img/techcore-logo.svg" alt="logo" />
					</button>
				</div>
				<div className={styles.footer_columns__wrapper}>
					<FooterColumn
						title={contactUs.title}
						options={contactUs.options}
						link={<a href={contactUs.link} target="_blank" rel="noreferrer">{contactUs.linkIcon}</a>}
					/>
					<div className={styles.footer_column__wrapper}>
						<FooterColumn
							title={companyFocus.title}
							options={companyFocus.options}
						/>
						<FooterColumn
							title={forTeam.title}
							options={forTeam.options}
						/>
						<FooterColumn
							title={products.title}
							options={products.options}
						/>
						<FooterColumn
							title={products_next.title}
							options={products_next.options}
						/>
					</div>
				</div>
				<div className={styles.copyright}>
					©Techcore 2024. All rights reserved. <span className={styles.copyright_link}>Privacy Policy</span> and <span className={styles.copyright_link}>Terms of Services</span>.
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;