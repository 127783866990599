import styles from "./primary-button.module.css"

/**
 * @param {string} type can be violet/blue/no_background (violet is default)
 */

const PrimaryButton = ({
    children,
    className,
    onClick,
    type = "violet",
    isEmpty = false
}) => {
    return (
        <div className={`${className} ${isEmpty && styles.empty} ${styles.primary_button} ${styles[type]}`}>
            <button onClick={onClick}>
                {children}
            </button>
        </div>
    )
}

export default PrimaryButton