import { cvManagementApi } from "../shared/constants/constants";


class CvApiService {
    static getHtmlFromS3 = async (data) => {
        try {
            const res = await fetch(`${cvManagementApi}/get-html-from-s3`, {
                method: 'POST',
                headers: {
                    "Content-Type": "text/html ; charset=utf-8"
                },
                body: JSON.stringify(data),
            });
            return await res.text();
        } catch (error) {
            console.log('error: ', error);
        }
    }
};

export default CvApiService;