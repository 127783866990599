import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CvApiService from '../services/cv-managment-api';

const CVHtml = () => {
    const [content, setContent] = useState("");
    const [head, setHead] = useState("");
    const { owner, id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                let ownerName = owner;
                let hashString = id;

                let response = await CvApiService.getHtmlFromS3({ ownerName: ownerName, hashString: hashString });
                if (response === undefined) {
                    return;
                } else {

                    const headAndBody = response.split('<head>')
                    const head = headAndBody[1].split('</head>')[0]
                    const body = headAndBody[1].split('</head>')[1]
                    // console.log(body);
                    setHead(head)
                    setContent(body);
                }
            } catch (err) {
                console.log(err);
            }
        })()
    }, [])

    const HtmlCv = () => {
        document.body.innerHTML = content
        document.head.innerHTML = head
        return document.body.innerHTML;
    }

    return (
        <>
            <HtmlCv />
        </>)
}

export default CVHtml;