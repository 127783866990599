import styles from "./top-menu.module.css"
import BusinessDropdownView from "./components/dropdown-views/business-dropdown-view"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import VendorsDropdownView from "./components/dropdown-views/vendors-dropdown-view"
import { links } from "../constants/constants"
import useScroll from "../../hooks/useScroll"
import { ReactComponent as TechcoreAI } from "../../shared/img/techcore-ai.svg"
import { Button, Modal } from "antd"
import { ReactComponent as CloseIcon } from "../../shared/img/modal-close.svg"
import JoinModal from "./components/modals/join-modal"
import * as Scroll from "react-scroll"

const TopMenu = ({ isCompact = false, hasBackground = true, borderBottom = false, children }) => {
	const navigate = useNavigate()
	const isScrolled = useScroll();
	const location = useLocation();
	const [isDropdownOpen, setIsDropDownOpen] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const scroller = Scroll.scroller;

	const isChatPage = location.pathname === "/ai-chat";

	const handleJoin = () => {
		setIsModalOpen(true);
	}

	const handleCancel = () => {
		setIsModalOpen(false);
	}
	const goToPagePageAndScroll = async (block) => {
		navigate("/products")
		setTimeout(() => {
			scroller.scrollTo(block)
		}, 100)
	}

	return (
		<div
			className={`
				${styles.top_menu_block} 
				${(hasBackground || isDropdownOpen) && styles.with_background} 
				${(borderBottom || isScrolled) && styles.border_bottom}`
			}
		>
			<div className={`${styles.top_menu}`}>
				<div className={`${styles.content} container`}>
					<button onClick={() => navigate("/")} className={styles.logo_button}>
						{
							isChatPage ? (
								<TechcoreAI />
							) : (
								<img src="/img/techcore-logo.svg" alt="logo" />
							)
						}
					</button>

					<div className={styles.buttons_block}>
						{!isCompact && (
							<div className={styles.dropdown_buttons}>
								{/* <div className={styles.dropdown_button}>
									<BusinessDropdownView
										setIsDropdownOpen={setIsDropDownOpen}
										isAnyDropdownOpen={isDropdownOpen}
									/>
								</div> */}

								<div className={styles.dropdown_button}>
									<VendorsDropdownView
										setIsDropdownOpen={setIsDropDownOpen}
										isAnyDropdownOpen={isDropdownOpen}
									/>
								</div>

								{/* <Link to={links.personal} className={`${styles.section_button} ${isDropdownOpen && styles.gray} fs15 fw400 lh18`}>
									For Specialists
								</Link> */}

								<button onClick={() => goToPagePageAndScroll("pricing")} className={`${styles.pricing_button} ${isDropdownOpen && styles.gray}`} >
									Pricing
								</button>
								<Link to={`/contact`} className={`${styles.pricing_button} ${isDropdownOpen && styles.gray}`} >
									Contact
								</Link>
							</div>
						)}

						{children && children}

						<div className={styles.started_buttons}>
							<a
								href={`${links.vendors}sign-in`}
								style={{ width: "119px" }}
								className={`${styles.login_button} fs15 fw500 lh18`}
							>
								Sign In
							</a>
							<button
								// href={`${links.vendors}sign-up`}
								onClick={handleJoin}
								className={`${styles.get_started_button} fs15 fw500`}
							>
								Join
							</button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				title="What is your company focus?"
				open={isModalOpen}
				onCancel={handleCancel}
				closeIcon={<CloseIcon />}
				footer={false}
				destroyOnClose={true}
				width={460}
				closable
				centered
			>
				<JoinModal />
			</Modal>
		</div>
	)
}

export default TopMenu