export const getCompaniesList = (value) => {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(value),
	};

	const response = fetch(
		"https://nxfb8tjzzd.execute-api.us-west-1.amazonaws.com/prod/get-companies-by-skill-sort-by-rate",
		requestOptions
	)
		.then((response) => response.json())
		.then((data) => data)
		.catch((data) => data);
	return response;
};
