import React from 'react'
import styles from "../index.module.css"
import HubspotForm from 'react-hubspot-form'
import AntSpin from '../../shared/ant-spin/ant-spin'

const FormBlock = () => {
	return (
		<div className={styles.form_block}>
			<p className={styles.title}>Book your free demo</p>
			<div className={styles.form}>
				<HubspotForm
					portalId='143870587'
					formId='a8af2bfb-4365-4cc5-9e7d-03462f8472fc'
					onSubmit={() => console.log('Submit!')}
					onReady={(form) => console.log('Form ready!')}
					loading={<div style={{ display: "flex", justifyContent: "center", flex: "1", marginTop: "50px" }}><AntSpin size={50} /></div>}
				/>
			</div>
		</div>
	)
}

export default FormBlock