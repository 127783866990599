import i18n from "../locales/i18n";

const { t } = i18n;
const I18_KEY_PREFIX = "landing-page.pricing";

export const calculationType = {
	PRO: 0,
	NOT_PRO: 1,
};

export const planNames = {
	free: t("shared.plans.free-label"),
	standard: t("shared.plans.standard-label"),
	pro: t("shared.plans.pro-label"),
};
export const planTypes = {
	monthly: t("shared.monthly-label"),
	annually: t("shared.annually-label"),
};

export const plansDescription = {
	[planNames.free]: [
		{
			title: t(`${I18_KEY_PREFIX}.up-to-amount-of-employees-label`, {
				amount: 10,
			}),
			isShowCheckIcon: false,
		},
		{ title: "Includes:", isShowCheckIcon: false },
		{ title: t("shared.employee-directory-label"), isShowCheckIcon: true },
		{ title: t("shared.vacations-label"), isShowCheckIcon: true },
		{ title: t("shared.projects-label"), isShowCheckIcon: true },
		{ title: t("shared.time-journals-label"), isShowCheckIcon: true },
		{
			title: t("landing-page.footer.providers-search"),
			isShowCheckIcon: true,
		},
	],
	Free: [
		{
			title: t(`${I18_KEY_PREFIX}.up-to-amount-of-employees-label`, {
				amount: 10,
			}),
			isShowCheckIcon: false,
		},
		{ title: "Includes:", isShowCheckIcon: false },
		{ title: t("shared.employee-directory-label"), isShowCheckIcon: true },
		{ title: t("shared.vacations-label"), isShowCheckIcon: true },
		{ title: t("shared.projects-label"), isShowCheckIcon: true },
		{ title: t("shared.time-journals-label"), isShowCheckIcon: true },
		{
			title: t("landing-page.footer.providers-search"),
			isShowCheckIcon: true,
		},
	],
	[planNames.standard]: [
		{
			title: t(`${I18_KEY_PREFIX}.unlimited-employees-label`),
			isShowCheckIcon: false,
		},
		{
			title: t(`${I18_KEY_PREFIX}.all-as-in-label`, {
				planName: planNames.free,
			}),
			isShowCheckIcon: false,
		},
		{ title: t("shared.finances-label"), isShowCheckIcon: true },
		{ title: t("shared.compensation-label"), isShowCheckIcon: true },
	],
	[planNames.pro]: [
		{
			title: t(`${I18_KEY_PREFIX}.unlimited-employees-label`),
			isShowCheckIcon: false,
		},
		{
			title: t(`${I18_KEY_PREFIX}.all-as-in-label`, {
				planName: planNames.standard,
			}),
			isShowCheckIcon: false,
		},
		{ title: t("shared.resources-planning-label"), isShowCheckIcon: true },
		{ title: t("shared.recruiting-label"), isShowCheckIcon: true },
		{ title: t("shared.analytics-label"), isShowCheckIcon: true },
	],
};
