import React, { useState } from 'react'
import DescrBlock from './DescrBlock'
import { listOfDescriptors, listOfDescriptorsBuyers, listOfDescriptorsVendors } from './utils'
import styles from "./index.module.css"
import { Segmented } from 'antd'

const types = {
	Buyers: "Buyers",
	Providers: "Vendors",
}

const HelpBlock = () => {
	const [isBuyerType, setIsBuyerType] = useState(types.Buyers);
	return (
		<div className="container" style={{ paddingBottom: "120px" }}>
			<div className={styles.help_block}>
				<h2 className={styles.help_block__title}>Our solutions for <span className={styles.active}>Buyers & Vendors</span></h2>
				<p className={styles.help_block__descr}>As a B2B match-making marketplace, we help both sides — buyers and vendors — to find each other and establish partnerships of trust.</p>

				<Segmented
					options={[types.Buyers, types.Providers]}
					className={styles.help_block__buttons}
					onChange={(value) => {
						setIsBuyerType(value); // string
					}}
				/>
				{/* <div className={styles.help_block__buttons}>
					<button>
						Buyers
					</button>
					<button>
						Providers
					</button>
				</div> */}
			</div>
			<div className={styles.help_block__descr_wrapper}>
				{(isBuyerType === types.Buyers ? listOfDescriptorsBuyers : listOfDescriptorsVendors).map((item, i) => (
					<DescrBlock data={item} key={i} />
				))}
			</div>
		</div>
	)
}

export default HelpBlock