import SolutionsDropdown from "../../../../vendors-page/components/solutions-dropdown/solutions-dropdown"
import DropdownCase from "../dropdown-case/dropdown-case"
import CustomDropdown from "./custom-dropdown"

const VendorsDropdownView = ({ setIsDropdownOpen, isAnyDropdownOpen }) => {
	return (
		<CustomDropdown
			className="solutions-dropdown"
			buttonTitle="Products"
			dropdownTitle="Products"
			setIsDropdownOpen={setIsDropdownOpen}
			isAnyDropdownOpen={isAnyDropdownOpen}
		>
			<SolutionsDropdown setIsDropdownOpen={setIsDropdownOpen} />
		</CustomDropdown>
	)
}

export default VendorsDropdownView