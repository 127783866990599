import React from 'react'
import DescriptionBlock from './components/DescriptionBlock'
import FormBlock from './components/FormBlock'
import FooterComponent from '../footer/FooterComponent'
import styles from "./index.module.css"

const ContactPage = () => {
	return (
		<section>
			<div className={`container ${styles.blocks_wrapper}`}>
				<DescriptionBlock />
				<FormBlock />
			</div>
			<FooterComponent />
		</section>
	)
}

export default ContactPage