export const listOfDescriptorsBuyers = [
	{
		title: "Find best tech partner",
		descr: "Find the best tech partner by searching registered vendors based on services, rates, and industries. An embedded AI chat assistant interacts with users to refine search results, ensuring a perfect match for your IT needs.",
	},
	{
		title: "Check available talents",
		descr: "Get immediate access to IT professionals ready to start work become easy now. Buyers can review anonymized profiles shared by vendors, assessing skills and experience to quickly find the right talent for urgent projects.",
	},
	{
		title: "Get a quote",
		descr: "Post project briefs and receive proposals from matched vendors. This efficient process ensures competitive quotes, enabling buyers to choose the best provider for their project needs.",
	},
];
export const listOfDescriptorsVendors = [
	{
		title: "Attract new clients",
		descr: "Attract new clients by defining their service lines, either manually or automatically based on employee skills. This automatic method boosts trust and earns a Pro badge, enhancing visibility in listings.",
	},
	{
		title: "Show your strengths",
		descr: "Showcase available talent with anonymised profiles, speeding up utilisation. When preselected for a project, vendors can provide detailed proposals, including project plans, timelines, and costs to potential buyers.",
	},
	{
		title: "Streamline your operations",
		descr: "Use highly granular search criteria and versatile filtering to find the best-matching IT providers. Name, save, and retrieve multiple search filters and fine-tune settings over time.",
	},
];
