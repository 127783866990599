import React from "react";
import styles from "./index.module.css"


const LandingTag = ({ title }) => {
	return (
		<div className={styles.tag}>{title}</div>
	);
};

export default LandingTag;