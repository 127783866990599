import React from "react";
import FooterLink from "./FooterLink";
import styles from "./index.module.css";

const FooterColumn = ({ title, link, options }) => {
	return (
		<div className={styles.footer_colum}>
			<h3 className={styles.footer_column__title}>{title}</h3>
			<div className={styles.footer_colum__links}>
				{
					options.map((option, i) => (
						<FooterLink
							title={option.title}
							isComingSoon={option?.isComingSoon}
							productName={option?.productName}
							link={option.link}
							key={i}
							target={option?.target}
							rel={option?.rel}
						/>
					))
				}
			</div>
			{link}
		</div>
	);
};

export default FooterColumn;