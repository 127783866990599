import React, { useEffect, useRef, useState } from 'react'
import styles from "./index.module.css"
import InputComponent from './components/InputComponent'
import MessageComponent from './components/MessageComponent'
import { useForm } from 'antd/es/form/Form'
import { postPrompt } from './service/bot-api'


const AiChat = () => {
	const dummy = useRef(null);
	const [messages, setMessages] = useState([{
		text: "Tell me about the business problem you are trying to solve, and I will help you find a service provider for your needs. Based on your initial information, I may ask a few questions to narrow down the best results.",
		isOwn: false
	}])
	const [sessionId, setSessionId] = useState("");
	const [loading, setLoading] = useState(false);
	const [form] = useForm()

	const onFinish = async (values) => {
		setLoading(true);
		if (values.prompt) {
			form.resetFields()
			setMessages(prev => [...prev, { text: values.prompt, isOwn: true }]);
			try {
				const response = await postPrompt({
					...values,
					session_id: sessionId
				});
				//* need to add handler error
				// if (response.response.output) {
				let { output } = response.response || {}
				setSessionId(response.session_id)
				setMessages(prev => [...prev, { text: output, isOwn: false }]);
				// } else {
				// 	console.error(response.response)
				// }
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (dummy.current) {
			dummy.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]);

	return (
		<div className={styles.ai_chat}>
			<div className={styles.background}>
				{/* <div className={styles.ai_chat__icon}>
					<div className={styles.ai_block__icon} >

					</div>
				</div> */}
				<div className={styles.wrapper} style={{ height: "100%", background: "white", overflow: "auto", }}>
					<div className={styles.messages_wrapper}>
						{
							messages.map((item, index) => {
								return <MessageComponent key={index} isOwn={item.isOwn} text={item.text} />
							})
						}
						<div ref={dummy} style={{ minHeight: "1px" }} />
					</div>
				</div>
				<InputComponent setMessages={setMessages} loading={loading} onFinish={onFinish} form={form} />
			</div>
		</div>
	)
}

export default AiChat