import { Button } from "antd"
import { ReactComponent as IconClose } from "../img/icon-close.svg"
import styles from "./close-icon.module.css"

const CloseButton = ({ onClick }) => {
    return (
        <Button onClick={onClick} className={styles.close_button}>
            <IconClose className={styles.icon} />
        </Button>
    )
}

export default CloseButton