import { Dropdown } from "antd"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { links } from "../../../constants/constants"
import styles from "./dropdown-views.module.css"

const CustomDropdown = ({
	children,
	buttonTitle,
	dropdownTitle,
	dropdownInfo,
	setIsDropdownOpen,
	isAnyDropdownOpen,
	redirectLink,
	className
}) => {
	const [visible, setVisible] = useState(false)
	const navigate = useNavigate()

	const dropdownView = () => {
		return (
			<div className={`${styles.dropdown} ${className}`}>
				<div className={styles.dropdownInfo_wrapper}>
					<h3 className={`${styles.title} fs26 fw600 lh28`}>{dropdownTitle}</h3>

					{dropdownInfo && (
						<p className={`${styles.dropdown_info} main-gray fs16 fw400 lh22`}>{dropdownInfo}</p>
					)}
				</div>
				<div className={styles.cases}>
					{children}
				</div>
			</div>
		)
	}

	const handleRedirect = () => {
		setIsDropdownOpen(false);
		navigate("/products");
	}

	return (
		<Dropdown
			// trigger="click"
			open={isAnyDropdownOpen}
			onOpenChange={(value) => {
				setVisible(value)
				setIsDropdownOpen(value)
			}}
			overlayClassName={styles.dropdown_root}
			dropdownRender={dropdownView}
			getPopupContainer={trigger => trigger.parentElement}

		>
			<button
				className={`${styles.dropdown_button} ${(isAnyDropdownOpen && !visible) && styles.gray}`}
				onClick={handleRedirect}
			>
				<Link to={redirectLink} className={`${styles.button_title} fs15 fw400 lh18`}>
					{buttonTitle}
				</Link>
				<img className={styles.arrow_icon} style={{ transform: isAnyDropdownOpen && "rotate(180deg)" }} src="/img/arrow-bottom.svg" alt="arrow-icon" />
			</button>
		</Dropdown >
	)
}

export default CustomDropdown