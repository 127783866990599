import React from "react";

import { ReactComponent as LinkedinIcon } from "../shared/img/icon-linkedin.svg";
import { links } from "../shared/constants/constants";
import i18n from "../../locales/i18n";

const { t } = i18n;
const googleMapsLink =
	"https://www.google.com/maps/search/?api=1&query=Poland+Warsaw%2C+st.+Nowogrodzka+31%2F413";

export const footerColumns = {
	contactUs: {
		title: "Contact Us",
		link: "https://www.linkedin.com/company/techcore-io",
		linkIcon: <LinkedinIcon />,
		options: [
			{
				title: "Warsaw, st. Nowogrodzka 31/413",
				link: googleMapsLink,
				target: "_blank",
				rel: "noreferrer",
			},
			{
				title: "contact@techcore.io",
				link: "mailto:contact@techcore.io",
			},
		],
	},
	companyFocus: {
		title: "Solutions by Company Focus",
		options: [{ title: "Product Company" }, { title: "Service Provider" }],
	},
	forTeam: {
		title: "Solutions by Team",
		options: [
			{ title: "Marketing" },
			{ title: "Human Resources" },
			{ title: "Operations" },
			{ title: "PMO" },
		],
	},
	products: {
		title: "Products",
		options: [
			{
				title: t("shared.employee-directory-label"),
				productName: "people",
			},
			{
				title: t("shared.vacations-label"),
				productName: "vacation_tracker",
			},
			{ title: t("shared.projects-label"), productName: "projects" },
			{
				title: t("shared.time-journals-label"),
				productName: "time_tracker",
			},
			{
				title: t("shared.resources-planning-label"),
				productName: "resources_planning",
			},
		],
	},
	products_next: {
		title: "",
		options: [
			{
				title: t("shared.recruiting-label"),
				productName: "hr_management",
			},
			{
				title: t("shared.finances-label"),
				isComingSoon: true,
				productName: "finances",
			},
			{
				title: t("shared.compensation-label"),
				productName: "compensation",
			},
			{
				title: t("shared.analytics-label"),
				isComingSoon: true,
				productName: "analytics",
			},
		],
	},
};
