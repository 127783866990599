const fetchNode = async (url, params = {}) => {
	if (!params.headers) {
		params.headers = {
			"Content-Type": "application/json",
		};
	}
	const response = await fetch(url, params);
	const json = await response.json();

	// if (json && response.ok) {
	// 	localStorage.clear();
	// 	return;
	// }
	// console.log("response=", json);
	return json;
};

export async function postReq(url, data, signal) {
	try {
		let res = await fetchNode(url, {
			method: "POST",
			// credentials: "include",
			signal: signal,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		console.log(res);
		return res;
	} catch (error) {
		console.log("error: ", error);
	}
}
