import React from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const NotFoundPage = () => {
	let navigate = useNavigate();
	return (
		<div className={styles.main_page}>

			<div className={styles.background}>
				{/* <img src={errorCloud} alt="error_img" /> */}
				<h3 className={styles.error_title}>Page not found</h3>
				<Button
					onClick={() => navigate("/")}
					type="primary"
					className={styles.button}
				>
					Go home
				</Button>

			</div>
		</div>
	);
};

export { NotFoundPage };
