import React from 'react'
import styles from "../index.module.css"
import { ReactComponent as Avatar } from "../../shared/img/ai-avatar.svg"

const MessageComponent = ({ isOwn, text }) => {
	return (
		<div className={`${styles.ai_chat__message_wrapper} ${isOwn && styles.own}`}>
			{
				!isOwn && (
					<div className={styles.ai_chat__avatar}>
						<Avatar />
					</div>
				)
			}
			<div className={`${styles.ai_chat__message} ${isOwn && styles.own}`}>
				{text}
			</div>
		</div >
	)
}

export default MessageComponent