import React, { useState } from 'react'
import styles from "../index.module.css"
import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ReactComponent as MessageIcon } from "../../shared/img/icon-message.svg"
import AntSpin from '../../shared/ant-spin/ant-spin'

const InputComponent = ({ loading, onFinish, form }) => {


	return (
		<div className={styles.ai_input__wrapper}>
			<Form className={styles.form} onFinish={onFinish} form={form}>
				<Form.Item
					label=""
					name="prompt"
					style={{ maxWidth: "678px", width: "100%" }}
				>
					<TextArea
						autoSize={false}
						disabled={loading}
						onPressEnter={() => form.submit()}
						style={{ maxHeight: "40px", resize: "none", padding: "9px 16px" }}
						placeholder="Describe what services you are looking for"
					/>
				</Form.Item>
				{
					loading ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<AntSpin size="large" />
						</div>
					) : (
						<button disabled={loading} className={styles.ai_input__button}>{<MessageIcon />}</button>
					)
				}
			</Form>
		</div >
	)
}

export default InputComponent