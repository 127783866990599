import { Button, Checkbox, Form, Input, Spin, Tag } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import AntSpin from "../shared/ant-spin/ant-spin"
import PageNavigationBlock from "../shared/page-navigation-block/page-navigation-block"
import TopMenu from "../shared/top-menu/top-menu"
import styles from "./companies-list.module.css"
import CompanyCard from "./components/company-card/company-card"
import { formatSearchTag, handlerSearchTagName, industries, initialActivitiesList, initialActivitiesListinSelect, rateList } from "./helpers/companies-list-helpers"
import { getCompaniesList } from "./service/companies-list-api"
import LocationSelect from "../shared/location-select/location-select"
import stylesSelect from "../shared/activity-select/activity-select.module.css"
import SelectWithCheckbox from "../shared/select-with-checkbox/SelectWithCheckbox"
import useDebounce from "../hooks/useDebounce"
import AIBlock from "../ai-components/AIBlock"
import FooterGroup from "../footer/FooterGroup"


const CompaniesList = () => {
	const [form] = useForm()
	const [formValues, setFormValues] = useState({})
	const [companiesList, setCompaniesList] = useState([])
	const [loading, setLoading] = useState(false)
	const [isFirstLoading, setIsFirstLoading] = useState(false)
	const { search_tag } = useParams();
	const [searchValue, setSearchValue] = useState("");
	const [filteredData, setFilteredData] = useState(initialActivitiesListinSelect.filter(elem => elem.value !== search_tag));
	const [isOpen, setIsOpen] = useState(false);
	const [isActiveServices, setIsActiveServises] = useState(false);
	const [tags, setTags] = useState([]);
	const [isActiveRates, setIsActiveRates] = useState(false);
	const [isOpenRates, setIsOpenRates] = useState(false);
	const [isOpenIndustries, setIsOpenIndustries] = useState(false);
	const [isActiveIndustries, setIsActiveIndustries] = useState(false);
	const [isUpdated, setIsUpdated] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [searchTerm, setSearchTerm] = useState({
		data: {
			main: search_tag,
			filters: {
				categories: [],
				hourlyRate: [],
				industry: [],
			}
		}
	});
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		if (!isFirstRender) {

			if (debouncedSearchTerm) {
				(async () => {
					try {
						setLoading(true);
						const response = await getCompaniesList({ ...searchTerm.data })

						if (response.statusCode === 200) {
							setCompaniesList(response.response)
						} else {
							setCompaniesList([])
						}
						setLoading(false);
					} catch (error) {
						setLoading(false);
						console.log(error)
					}
				})()

			} else {
				setLoading(false);
			}

		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!isFirstRender) {
			let services = form.getFieldsValue().services;
			let rates = form.getFieldsValue().hourly_rate;
			let industriesList = form.getFieldsValue().industry;

			if (services?.length > 0) {
				setIsActiveServises(true)
			} else {
				setIsActiveServises(false)
			}

			if (rates?.length > 0) {
				setIsActiveRates(true)
			} else {
				setIsActiveRates(false)
			}

			if (industriesList?.length > 0) {
				setIsActiveIndustries(true)
			} else {
				setIsActiveIndustries(false)
			}

			let servicesTags = services?.length > 0 ? [...services.map(elem => {
				return {
					...initialActivitiesListinSelect.find(item => item.value === elem),
					select: "Services"
				}
			})] : [];

			let ratesTags = rates?.length > 0 ? [...rates.map(elem => {
				return {
					...rateList.find(item => item.value === elem),
					select: "Hourly rate"
				}
			})] : [];
			let industriesTags = industriesList?.length > 0 ? [...industriesList.map(elem => {
				return {
					value: industries.find(item => item === elem),
					label: industries.find(item => item === elem),
					select: "Industry"
				}
			})] : [];

			setTags([...servicesTags, ...ratesTags, ...industriesTags])
			let dataObj = {
				main: search_tag,
				filters: {
					categories: services?.length > 0 ? [...services] : [],
					hourlyRate: rates?.length > 0 ? [...rates] : [],
					industries: industriesList?.length > 0 ? [...industriesList] : [],
				}
			}
			setSearchTerm({
				data: {
					...dataObj
				}
			})
		}
	}, [isUpdated]);

	const handleFinish = (e) => {
		setFormValues(e)
	}

	useEffect(() => {
		(async () => {
			try {
				setIsFirstLoading(true);
				const response = await getCompaniesList({
					main: search_tag,
					filters: {
						categories: [],
						hourlyRate: [],
						industry: [],
					}
				})

				if (response.statusCode === 200) {
					setCompaniesList(response.response)

					//* update page title
					const mydate = new Date();
					const month = mydate.toLocaleString('en-US', { month: 'long' });
					const year = mydate.getFullYear();
					const formattedDate = `${month} ${year}`;

					let newTitle = `Top ${handlerSearchTagName(search_tag)} ${((search_tag === "ux-ui-design") || (search_tag === "web-design")) ? "Studios" : "Companies"} - ${formattedDate}`
					document.title = newTitle;

					setIsFirstRender(false)
				} else {
					setIsFirstRender(false)
					setCompaniesList([])
				}
				setIsFirstLoading(false);
			} catch (error) {
				setIsFirstLoading(false);
				console.log(error)
			}
		})()
		return () => {
			document.title = "Techcore";
		};
	}, [])


	const focusName = formatSearchTag(search_tag)

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchValue(value);

		const filtered = initialActivitiesListinSelect
			.filter(elem => elem.value !== search_tag)
			.filter((item) =>
				item.label.toLowerCase().includes(value.toLowerCase())
			);
		setFilteredData(filtered);
	};

	const mapServises = (list) => {
		if (list.length > 0) {
			return list.map((item, i) => {
				return {
					key: item.value,
					label: (
						<Checkbox
							className="dropdown_checkbox"
							value={item.value}
							onChange={() => setIsUpdated((prev) => !prev)}
						>
							{item.label}
						</Checkbox>
					)
				}
			})
		} else {
			return [{
				key: "empty",
				label: (
					<div>No Services found</div>
				)
			}]
		}
	}

	const mapRates = (list) => {
		if (list.length > 0) {
			return list.map((item, i) => {
				return {
					key: item.value,
					label: (
						<Checkbox
							className="dropdown_checkbox"
							value={item.value}
							onChange={() => setIsUpdated((prev) => !prev)}
						>
							{`${item.label}/hr`}
						</Checkbox>
					)
				}
			})
		} else {
			return [{
				key: "empty",
				label: (
					<div>No Rates found</div>
				)
			}]
		}
	}

	const mapIndustries = (list) => {
		if (list.length > 0) {
			return list.map((item, i) => {
				return {
					key: item,
					label: (
						<Checkbox
							className="dropdown_checkbox"
							value={item}
							onChange={() => setIsUpdated((prev) => !prev)}
						>
							{item}
						</Checkbox>
					)
				}
			})
		} else {
			return [{
				key: "empty",
				label: (
					<div>No Rates found</div>
				)
			}]
		}
	}

	let searchBlock = {
		key: "search",
		label: (
			<Input
				value={searchValue}
				onChange={handleSearch}
				prefix={(<img src="/img/icon-search.svg" alt="search-icon" />)}
				placeholder="Search..."
				className="dropdown_input"
			/>
		)
	}

	const handleClose = (removedTag, tags) => {
		const newTags = tags.filter((tag) => tag.value !== removedTag.value);
		if (removedTag.select === "Services") {
			let newServices = form.getFieldsValue().services.filter(elem => elem !== removedTag.value);
			form.setFieldsValue({ "services": newServices })
		} else if (removedTag.select === "Hourly rate") {
			let newRates = form.getFieldsValue().hourly_rate.filter(elem => elem !== removedTag.value);
			form.setFieldsValue({ "hourly_rate": newRates })
		} else if (removedTag.select === "Industry") {
			let newIndustry = form.getFieldsValue().industry.filter(elem => elem !== removedTag.value);
			form.setFieldsValue({ "industry": newIndustry })
		}

		setIsUpdated(prev => !prev);
		setTags(prev => [...newTags]);
	}


	return (
		<div style={{ overflow: "hidden" }}>
			<div className={styles.companies_list}>

				<div className={`${styles.content} container`}>
					<div className={styles.navigation_block}>
						<PageNavigationBlock
							redirectLink="/"
							redirectPageName="Home"
							currentPageName={handlerSearchTagName(search_tag)}
						/>
					</div>

					<div className={styles.title_block}>
						<h1 className={`${styles.title} fs34 fw500 lh40`}>
							Top {handlerSearchTagName(search_tag)} {((search_tag === "ux-ui-design") || (search_tag === "web-design")) ? "Studios" : "Companies"}
						</h1>
					</div>

					<div className={styles.filters_block}>
						{/* <div className={styles.sorting_block}>
						<p className={`${styles.firms_amount} fs14 fw400 lh16`}>27,636 Firms</p>

						<div className={styles.sorting}>
							<p className={`${styles.preffix_text} main-gray fs14 fw500 lh16`}>Sort by:</p>

							<div className={styles.select}>
								<Select
									value="sponsored"
									suffixIcon={<img src="/img/select-arrow-icon.svg" alt="icon" />}
								>
									<Select.Option value="sponsored">Sponsored</Select.Option>
								</Select>
							</div>
						</div>
					</div> */}


						<Form
							form={form}
							onFinish={handleFinish}
							onChange={() => form.submit()}
						>
							<div className={styles.filters}>
								{/* <Form.Item
								name="search"
								className={styles.filter_search}
								style={{ width: "355px" }}
							>
								<LocationSelect />
							</Form.Item> */}
								<Form.Item
									name="services"
									className={styles.filter_item}
								>
									<Checkbox.Group>
										<SelectWithCheckbox
											setIsUpdated={setIsUpdated}
											isActive={isActiveServices}
											label="Services"
											overlayClassName={"dropdown_search-checkbox"}
											setIsOpen={setIsOpen}
											items={[searchBlock, ...mapServises(filteredData)]}
											isOpen={isOpen}
										/>
									</Checkbox.Group>
								</Form.Item>
								{/* <Form.Item name="Client Budget" initialValue="Client Budget" className={styles.filter_item}>
								<Select
									suffixIcon={<img src="/img/select-arrow-icon.svg" alt="icon" />}
								>
									<Select.Option value="budget">Client Budget</Select.Option>
								</Select>
							</Form.Item> */}
								<Form.Item name="hourly_rate" className={styles.filter_item}>
									<Checkbox.Group>
										<SelectWithCheckbox
											setIsUpdated={setIsUpdated}
											label="Hourly Rate"
											isActive={isActiveRates}
											setIsOpen={setIsOpenRates}
											items={[...mapRates(rateList)]}
											isOpen={isOpenRates}
										/>
									</Checkbox.Group>
								</Form.Item>
								<Form.Item name="industry" className={styles.filter_item}>
									<Checkbox.Group>
										<SelectWithCheckbox
											setIsUpdated={setIsUpdated}
											label="Industry"
											isActive={isActiveIndustries}
											setIsOpen={setIsOpenIndustries}
											items={[...mapIndustries(industries)]}
											isOpen={isOpenIndustries}
										/>
									</Checkbox.Group>
								</Form.Item>
								{/* <Form.Item name="Reviews" initialValue="Reviews" className={styles.filter_item}>
								<Select
									suffixIcon={<img src="/img/select-arrow-icon.svg" alt="icon" />}
								>
									<Select.Option value="budget">Reviews</Select.Option>
								</Select>
							</Form.Item> */}
								{/* {selectedFiltersOptionsView()} */}
								{/* <Button
								className={styles.form_button}
								type="no_background"
								onClick={() => form.submit()}
							>
								All Filters
							</Button> */}
								<Button
									className={`${styles.form_button} ${styles.form_button__light}`}
									type="no_background"
									onClick={() => {
										form.resetFields()
										setIsUpdated(prev => !prev)
									}}
								>
									Clear All
								</Button>
							</div>
							<div
								className={styles.tags_wrapper}
								style={{ marginTop: (tags.length > 0) && "8px" }}
							>
								{
									tags.map(tag => (
										<Tag
											className={styles.tag}
											key={tag.label}
											closable
											onClose={() => handleClose(tag, tags)}
											closeIcon={<img src="/img/icon-close.svg" alt="close_icon" />}
										>
											<span className={styles.tag_select}>
												{`${tag.select}:`}
											</span>

											{tag.label}

										</Tag>
									))
								}
							</div>
						</Form>
					</div>
					<div
						className={styles.companies_cards}
						// style={{ justifyContent: (loading || companiesList.length === 0) && "center" }}
						style={{ justifyContent: (isFirstLoading) && "center" }}
					>
						{
							isFirstLoading ? (
								<AntSpin size={50} />
							) : (
								<Spin indicator={<AntSpin size={50} />} spinning={loading} >
									{
										companiesList.length > 0 ? (
											<div className={styles.companies_card_wrapper}>
												{
													companiesList.map((data, i) => (
														<CompanyCard data={data} key={i * new Date().getTime()} searchTag={search_tag} focusName={focusName} />
													)
													)
												}
											</div>
										) : (
											<div className={styles.empty_content}>No Companies found</div>
										)
									}
								</Spin>
							)
						}

					</div>
				</div>
			</div >
			<AIBlock />
			<FooterGroup />
		</div>
	)
}

export default CompaniesList