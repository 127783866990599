import { useLocation } from "react-router-dom";
import "./App.css";
import TopMenu from "./components/shared/top-menu/top-menu";
import "./fonts/fonts.css";
import Navigation from "./main/navigation";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

function App() {
	const location = useLocation();
	const shouldShowTopMenu = location.pathname !== "/404";
	return (
		<I18nextProvider i18n={i18n}>
			{shouldShowTopMenu && <TopMenu hasBackground={true} />}
			<Navigation />
		</I18nextProvider>
	);
}

export default App;
