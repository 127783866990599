import React from 'react'
import styles from "./company-card.module.css"
import { ReactComponent as ProLabelIcon } from "../../../shared/img/pro-label.svg"
import { Tooltip } from 'antd'

const ProLabel = () => {
	return (
		<Tooltip
			placement="top"
			overlayClassName={styles.card_tooltip}
			title={`Company service focus is calculated based on skills of employees working in this company.  Learn how it works here.`}
		>
			<div className={styles.pro_label}>
				<ProLabelIcon className={styles.pro_label_icon} />
				<p>Pro</p>
			</div>
		</Tooltip>
	)
}

export default ProLabel