import React from 'react'
import FooterComponent from '../footer/FooterComponent'
import { Element } from 'react-scroll'
import InfoBlock from '../main-page/components/info-block/info-block'
import styles from "./index.module.css"
import { useTranslation } from 'react-i18next'
import LandingTag from './LandingTag'
import PricingPage from './Pricing/PricingPage'
import { ReactComponent as TimeTrackerIcon } from "../shared/img/time-tracker-icon.svg"
import { ReactComponent as VacationTrackerIcon } from "../shared/img/vacation-tracker-icon.svg"
import { ReactComponent as ResourceIcon } from "../shared/img/resource-icon.svg"
import { ReactComponent as HrManagementIcon } from "../shared/img/hr-management-icon.svg"
import { ReactComponent as FinancesIcon } from "../shared/img/icon-finances.svg"
import { ReactComponent as ChartIcon } from "../shared/img/icon-chart.svg"
import { ReactComponent as ProjectsIcon } from "../shared/img/projects-icon.svg"
import { ReactComponent as CompensationIcon } from "../shared/img/compensation-icon.svg"
import { ReactComponent as PeopleIcon } from "../shared/img/people-icon.svg"

const LandingPage = () => {
	const { t } = useTranslation();

	const TAGS = [t("shared.hr-management-label"), t("shared.time-journals-label"), t("shared.vacations-label"), t("shared.project-management-label"), t("shared.resources-planning-label"), t("shared.finances-label"), t("shared.analytics-label")];
	return (
		<>
			<div className={styles.main_page}>
				<div className={styles.background}>
					<div className={`${styles.content} ${styles.container}`}>
						<div className={styles.main_block}>
							<div className={styles.title_block}>
								<h1 className={`${styles.title}`}>
									<span className="main-blue fw600">
										{t("landing-page.intro.bold-label")}
									</span>
									{t("landing-page.intro.text")}
								</h1>
								<div className={styles.tags_wrapper}>
									{TAGS.map(item => (<LandingTag title={item} key={item} />))}
								</div>
							</div>
							<div className={styles.img_block}>
								<img src="/img/landing-illustration.svg" alt="illustration" />
							</div>
						</div>
					</div>
				</div>

				<div className={styles.info_cases_block}>
					<div className={styles.container}>
						<div className={styles.info_cases}>
							<Element name="people">
								<InfoBlock
									imgPath="/img/people-illustration.png"
									prefixTitleText={t("shared.employee-directory-label")}
									titleIcon={<PeopleIcon className={styles.title_icon} style={{ color: "red" }} />}
									descriptionText={t("landing-page.people-description")}
								/>
							</Element>
							<Element name="vacation_tracker">
								<InfoBlock
									imgPath="/img/vacation-tracker-illustration.png"
									prefixTitleText={t("shared.vacations-label")}
									titleIcon={<VacationTrackerIcon className={styles.title_icon} />}
									descriptionText={t("landing-page.vacations-description")}
									// align="right"
									secondImgPath="/img/vacation-tracker-second-illustration.png"
									secondImgStyles={{
										right: "38px",
										bottom: "-170px",
										widthL: "417px"

									}}
									align="right"
								/>
							</Element>
							<Element name="projects">
								<InfoBlock
									blockStyles={{
										marginTop: "120px"
									}}
									imgPath="/img/projects-illustration.png"
									prefixTitleText={t("shared.projects-label")}
									titleIcon={<ProjectsIcon className={styles.title_icon} />}
									descriptionText={t("landing-page.projects-description")}
								/>
							</Element>
							<Element name="time_tracker">
								<InfoBlock
									imgPath="/img/time-tracker-illustration.png"
									prefixTitleText={t("shared.time-journals-label")}
									titleIcon={<TimeTrackerIcon className={styles.title_icon} />}
									align="right"
									descriptionText={t("landing-page.time-journals-description")}
								/>
							</Element>
							<Element name="resources_planning">
								<InfoBlock
									// blockStyles={{
									// 	marginTop: "120px"
									// }}
									imgPath="/img/resources-planning-illustration.png"
									prefixTitleText={t("shared.resources-planning-label")}
									titleIcon={<ResourceIcon className={styles.title_icon} />}
									descriptionText={t("landing-page.resources-planning-description")}
								/>
							</Element>

							<Element name="hr_management">
								<InfoBlock
									imgPath="/img/hr-management-illustration.png"
									prefixTitleText={t("shared.recruiting-label")}
									titleIcon={<HrManagementIcon className={styles.title_icon} />}
									align="right"
									descriptionText={t("landing-page.hr-management-description")}
								/>
							</Element>
							<Element name="finances">
								<InfoBlock
									imgPath="/img/finances-illustration.png"
									prefixTitleText={t("shared.finances-label")}
									titleIcon={<FinancesIcon className={`${styles.title_icon} ${styles.stroke_fill}`} />}
									descriptionText={t("landing-page.finances-description")}
								/>
							</Element>
							<Element name="compensation">
								<InfoBlock
									imgPath="/img/compensation-illustration.png"
									prefixTitleText={t("shared.compensation-label")}
									titleIcon={<CompensationIcon className={styles.title_icon_white} />}
									descriptionText={t("landing-page.compensation-description")}
									align="right"
								/>
							</Element>
							<Element name="analytics">
								<InfoBlock
									imgPath="/img/analytics-illustration.png"
									prefixTitleText={t("shared.analytics-label")}
									titleIcon={<ChartIcon className={styles.title_icon} />}
									descriptionText={t("landing-page.analytics-description")}
									secondImgPath="/img/analytics-second-illustration.png"
									secondImgStyles={{
										right: "-66px",
										bottom: "-66px",
										width: "384px"
									}}
								/>
							</Element>

						</div>
					</div>
				</div>
				{/* <div className={styles.info_cases_block}> */}
				<Element name="pricing">
					<PricingPage />
				</Element>
				{/* </div> */}
			</div >
			<FooterComponent />
		</>
	)
}

export default LandingPage