import { useState } from "react"
import SelectWithRemoteSearch from "../select-with-remote-search/select-with-remote-search"
import styles from "./location-select.module.css"
import { Country } from "country-state-city"

const initialCountryList = Country.getAllCountries()

const getOptions = (title, list) => {
    return {
        group_name: title,
        options: list.map(item => {
            return {
                label: item.name,
                value: item.isoCode
            }
        })
    }
}

const LocationSelect = ({ value, onChange }) => {
    const [countriesList, setCountriesList] = useState(initialCountryList)
    const [options, setOptions] = useState(getOptions("ALL LOCATIONS", countriesList))

    const onSearch = (searchStr) => {
        if (searchStr.length === 0) {
            setCountriesList(initialCountryList)
            setOptions(getOptions("ALL LOCATIONS", initialCountryList))
            return
        }

        const filteredCountriesList = initialCountryList.filter(item => item.name.toLowerCase().trim().includes(searchStr.toLowerCase().trim()))

        setCountriesList(filteredCountriesList)
        setOptions(getOptions("MATCHING RESULTS", filteredCountriesList))
    }

    return (
        <SelectWithRemoteSearch
            options={[
                {
                    group_name: null,
                    options: [
                        { label: "Any Location", value: "any-location" }
                    ]
                },
                options
            ]}
            value={value}
            onSearch={onSearch}
            onChange={onChange}
            className={styles.select}
            suffixIcon={(<img src="/img/icon-location-blue.svg" alt="location-icon" />)}
            placeholder="Any Location"
        />
    )
}

export default LocationSelect