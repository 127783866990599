import React from 'react'
import styles from "./index.module.css"
import { Button } from 'antd'
import { ReactComponent as TechcoreAI } from "../shared/img/techcore-ai.svg"
import { useNavigate } from 'react-router-dom'
const AIBlock = () => {
	const navigate = useNavigate()

	return (
		<div className={styles.ai_block}>
			<div className={`${styles.ai_block__wrapper} container`}>
				<div className={styles.ai_block__icon} >
					<TechcoreAI />
				</div>
				<div className={styles.ai_block__textWrapper}>
					<div>
						<h3 className={styles.ai_block__title}>Fast-track your search for business services</h3>
						<p className={styles.ai_block__descr}>The future of B2B buying is here. Let TechcoreAI find your provider match.</p>
					</div>
				</div>
				<div>
					{/* <Button
						className={`${styles.ai_block__button}`}
						type="no_background"
						onClick={() => navigate(`/ai-chat`)}
					>
						Let’s Chat
					</Button> */}
				</div>
			</div>
		</div >
	)
}

export default AIBlock