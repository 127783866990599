import { useState } from "react"
import SelectWithRemoteSearch from "../select-with-remote-search/select-with-remote-search"
import styles from "./activity-select.module.css"
import { initialActivitiesList } from "../../companies-list/helpers/companies-list-helpers"
import { Select } from "antd"


const getOptions = (title, list) => {
	return {
		// group_name: title,
		options: list.map(item => {
			return {
				label: item.label,
				key: item.value,
				value: item.value
			}
		})
	}
}

const ActivitySelect = ({ form, }) => {
	const [activitiesList, setActivitiesList] = useState(initialActivitiesList)
	const [options, setOptions] = useState(getOptions("POPULAR SERVICES", activitiesList))
	const [value, setValue] = useState(null);

	const onSearch = (searchStr) => {
		if (searchStr.length === 0) {
			setActivitiesList(initialActivitiesList)
			setOptions(getOptions("POPULAR SERVICES", initialActivitiesList))
			return
		}

		const filteredActivitiesList = initialActivitiesList.filter(item => item.label.toLowerCase().trim().includes(searchStr.toLowerCase().trim()))

		setActivitiesList(filteredActivitiesList)
		setOptions(getOptions("MATCHING RESULTS", filteredActivitiesList))
	}
	const onChange = (e) => {
		// setValue(e)
		// form.setFieldsValue({ "activity": e.value });
		form.setFieldsValue({ "activity": e });
	}

	return (
		<Select
			showSearch
			className={styles.select}
			onChange={onChange}
			suffixIcon={(<img src="/img/icon-search.svg" alt="search-icon" />)}
			placeholder="e.g. App Development, UX Design, IT Services..."
			filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
			notFoundContent="No data available"
			virtual={false}
			popupClassName={styles.select_dropdown}
			options={
				options.options
			}
		/>
		// <SelectWithRemoteSearch
		// 	options={[
		// 		// {
		// 		// 	group_name: null,
		// 		// 	options: [
		// 		// 		{ label: "Any Development", value: "" }
		// 		// 	]
		// 		// },
		// 		options
		// 	]}
		// 	value={value}
		// 	onSearch={onSearch}
		// 	onChange={onChange}
		// 	className={styles.select}
		// 	suffixIcon={(<img src="/img/icon-search.svg" alt="search-icon" />)}
		// 	placeholder="e.g. App Development, UX Design, IT Services..."
		// />
	)
}

export default ActivitySelect