import React from 'react'
import styles from "../index.module.css"
import Options from './Options'

const OPTIONS = ["Request a demo", "Learn which plan is right for your team", "Get onboarding help"]

const DescriptionBlock = () => {
	return (
		<div className={styles.description_block}>
			<h2 className={styles.header}>See <span className='main-blue'>Techcore</span> in action</h2>
			<p className={styles.description}>
				Techcore is an all-in-one platform that centralizes and streamlines HR processes throughout the employee life cycle, ensuring the best employee experience.
			</p>
			<Options options={OPTIONS} />

			<p className={styles.description}>You can also email us at <a href="#" className='main-blue'>contact@techcore.io</a></p>
		</div>
	)
}

export default DescriptionBlock