import React from 'react'
import styles from "./index.module.css"

const DescrBlock = ({ data }) => {
	let { title, descr } = data || {};
	return (
		<div className={styles.descr_block}>
			<p className={styles.descr_block__title}>{title}</p>
			<p className={styles.descr_block__descr}>{descr}</p>
		</div>
	)
}

export default DescrBlock