import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../custom-buttons/primary-button/primary-button"
import SelectWithRemoteSearch from "../select-with-remote-search/select-with-remote-search"
import styles from "./company-search-block.module.css"
import { Form, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import LocationSelect from "../location-select/location-select"
import ActivitySelect from "../activity-select/activity-select"

const CompanySearchBlock = () => {
	const navigate = useNavigate()

	const [form] = useForm()

	const handleVendorButton = (value) => {
		if (value) {
			navigate(`/vendors/${value}`)
		} else {
			navigate(`/vendors`)
		}
	}

	const handleFinish = (e) => {
		const { activity, location } = e
		const activityValue = activity

		handleVendorButton(activityValue)
	}

	return (
		<div className={`${styles.search_block} container`}>
			<div className={styles.search_content}>
				<h1 className={`${styles.title} fs48 fw500 lh58`}>Discover the <span className="main-blue">Best Companies</span> for your Business Needs</h1>
				<p className={`${styles.description} main-gray fs18 fw400 lh26`}>Find your perfect business match with our service that ranks companies in real time by their employee competencies and availability.</p>

				<Form form={form} onFinish={handleFinish}>
					<div className={styles.filters_block}>
						<p className={`${styles.looking_for_text} fs15 fw400 lh18`}>I am looking for</p>
						<div className={styles.activity_select}>
							<Form.Item
								name="activity"
								rules={[
									{
										required: true,

									}

								]}
								className="no-validation-message"
							>
								<ActivitySelect form={form} />
							</Form.Item>
						</div>
						{/* <p className={styles.in_text}>in</p>
						<div className={styles.location_select}>
							<Form.Item name="location">
								<LocationSelect />
							</Form.Item>
						</div> */}
						<PrimaryButton
							className={styles.find_button}
							onClick={() => form.submit()}
							type="blue"
						>
							Find Vendor
						</PrimaryButton>
					</div>
				</Form>
			</div>
		</div>
	)
}

export default CompanySearchBlock