import { Button } from "antd"
import styles from "./solutions-dropdown.module.css"
import { ReactComponent as TimeTrackerIcon } from "../../../shared/img/time-tracker-icon.svg"
import { ReactComponent as VacationTrackerIcon } from "../../../shared/img/vacation-tracker-icon.svg"
import { ReactComponent as ResourceIcon } from "../../../shared/img/resource-icon.svg"
import { ReactComponent as HrManagementIcon } from "../../../shared/img/hr-management-icon.svg"
import { ReactComponent as HealthMonitoringIcon } from "../../../shared/img/health-monitoring-icon.svg"
import { ReactComponent as FinancesIcon } from "../../../shared/img/icon-finances.svg"
import { ReactComponent as ChartIcon } from "../../../shared/img/icon-chart.svg"
import { ReactComponent as ProjectsIcon } from "../../../shared/img/projects-icon.svg"
import { ReactComponent as CompensationIcon } from "../../../shared/img/compensation-icon.svg"
import { ReactComponent as PeopleIcon } from "../../../shared/img/people-icon.svg"
import { useMemo, useState } from "react"
import * as Scroll from "react-scroll"
import { links } from "../../../shared/constants/constants"
import { useNavigate } from "react-router-dom"

const CasesList = Object.freeze({
	time_tracker: "time_tracker",
	resources_planning: "resources_planning",
	vacation_tracker: "vacation_tracker",
	hr_management: "hr_management",
	projects_health_monitoring: "projects_health_monitoring",
	finances: "finances",
	projects: "projects",
	compensation: "compensation",
	people: "people",
	analytics: "analytics"
})

const SolutionsDropdown = ({ setIsDropdownOpen }) => {
	const scroller = Scroll.scroller;
	const navigate = useNavigate();

	const goToVendorsPageAndScroll = async (block) => {
		setIsDropdownOpen(prev => !prev)
		navigate("/products")
		setTimeout(() => {
			scroller.scrollTo(block)
		}, 100)
	}

	const [activeCase, setActiveCase] = useState(CasesList.time_tracker)

	const casesData = useMemo(() => {
		return [{
			name: CasesList.people,
			title: "Employee Directory",
			icon: (<PeopleIcon className={styles.case_title_icon} />),
			description: "All the information about people in your organisation including skills, past experience, documents, contracts information, compensation etc."
		}, {
			name: CasesList.time_tracker,
			title: "Time Journals",
			icon: (<TimeTrackerIcon className={styles.case_title_icon} />),
			description: "Track employee’s working hours, manage time sheets, get insights about team productivity and utilisation, generate reports."
		}, {
			name: CasesList.vacation_tracker,
			title: "Vacations",
			icon: (<VacationTrackerIcon className={styles.case_title_icon} />),
			description: "Effortless time off requests and approvals. Customise leave types and policies, set paid and non-paid balances, define accruals and brought forward rules. The system automates accounting and enables fast and easy requests and approvals for employee time via notifications from the platform Use leave timeline to see who will be absent and why, allowing for better work planning and coordination in the team."
		}, {
			name: CasesList.projects,
			title: "Projects",
			icon: (<ProjectsIcon className={styles.case_title_icon} />),
			description: "Create diverse project types and group them hierarchically by accounts and business units. Open and manage positions within projects, store crucial project details, and assign key staff roles seamlessly. Track and manage project budgets with ease, ensuring financial transparency and control. Enhance your IT project management with structured data and intuitive tools, streamlining operations from inception to completion."
		}, {
			name: CasesList.resources_planning,
			title: "Resources Planning",
			icon: (<ResourceIcon className={styles.case_title_icon} />),
			description: "Enable effective allocation and scheduling of staff and equipment, providing real-time monitoring and tracking of resources to increase productivity and profitability. Keep an eye on utilisation metrics and avoid long-time bench for people in your team."
		}, {
			name: CasesList.hr_management,
			title: "Recruiting",
			icon: (<HrManagementIcon className={styles.case_title_icon} />),
			description: `All information about candidates at your fingertips. Resumes, contacts, files, reviews and candidate ratings are available in one click. A well-thought-out applicants tracking system allows you to focus on the selection of talents, and not on finding the right information from hundreds of folders in your computer. Customise job vacancies funnels and create your own email templates. Control the hiring process, moving a candidate from stage to stage..`
		},
		{
			name: CasesList.finances,
			title: "Finances",
			icon: (<FinancesIcon className={styles.case_title_icon} />),
			description: "Streamline sales orders and invoices management by automating the process and reducing errors. It provides P&L reports, facilitates the planning of discounts for clients, and assists managers in comprehending the different revenue and cost factors that impact profitability. This helps organisations make informed financial decisions and maintain positive business relationships."
		},
		{
			name: CasesList.compensation,
			title: "Compensation",
			icon: (<CompensationIcon className={styles.case_title_icon_white} />),
			description: "Comprehensive employee compensation management. Create and approve salary proposals, add bonuses, and manage all compensation-related activities in one place. Access historical charts and current salary data, draft new salary proposals, and oversee department-wide salary costs. Forecast salary changes with precision, ensuring well-informed financial planning and control. Streamline your compensation processes to maintain accuracy and transparency across your organization."
		},
		{
			name: CasesList.analytics,
			title: "Analytics",
			icon: (<ChartIcon className={styles.case_title_icon} />),
			description: "Collect insights from all modules, allowing company to gain a comprehensive understanding of their operations. Data visualisation tools enable managers to make data-driven decisions to optimise their organisation and achieve goals. Insights help increase efficiency, profitability, and overall success, making it a crucial tool for operational improvement."
		}]
	}, [])

	const caseView = useMemo(() => {
		const selectedCaseData = casesData.find(item => item.name === activeCase)
		const { title, icon, description } = selectedCaseData

		return (
			<div className={styles.case_info_block}>
				<div className={styles.title_block}>
					{icon}
					<p className={`${styles.title} fs20 fw600 lh24`}>{title}</p>
				</div>

				<p className={`${styles.case_description} fs16 fw400 lh22`}>{description}</p>
			</div>
		)
	}, [activeCase])

	return (
		<div className={styles.solutions_dropdown_block}>
			<div className={styles.navigation_block}>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.people)}
					onClick={() => goToVendorsPageAndScroll("people")}
					className={styles.navigation_button}
				>
					<PeopleIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Employee Directory</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.vacation_tracker)}
					onClick={() => goToVendorsPageAndScroll("vacation_tracker")}
					className={styles.navigation_button}
				>
					<VacationTrackerIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Vacation Tracker</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.projects)}
					onClick={() => goToVendorsPageAndScroll(CasesList.projects)}
					className={styles.navigation_button}
				>
					<ProjectsIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Projects</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.time_tracker)}
					onClick={() => goToVendorsPageAndScroll("time_tracker")}
					className={styles.navigation_button}
				>
					<TimeTrackerIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Time Tracker</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.resources_planning)}
					onClick={() => goToVendorsPageAndScroll("resources_planning")}
					className={styles.navigation_button}
				>
					<ResourceIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Resources Planning</p>
				</Button>

				<Button
					onMouseEnter={() => setActiveCase(CasesList.hr_management)}
					onClick={() => goToVendorsPageAndScroll("hr_management")}
					className={styles.navigation_button}
				>
					<HrManagementIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Recruiting</p>
				</Button>

				{/* <Button
					onMouseEnter={() => setActiveCase(CasesList.projects_health_monitoring)}
					onClick={() => goToVendorsPageAndScroll("project_health_monitoring")}
					className={styles.navigation_button}
				>
					<HealthMonitoringIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Projects Health Monitoring</p>
				</Button> */}

				<Button
					onMouseEnter={() => setActiveCase(CasesList.finances)}
					onClick={() => goToVendorsPageAndScroll("finances")}
					className={styles.navigation_button}
				>
					<FinancesIcon className={`${styles.navigation_icon} ${styles.stroke_fill}`} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Finances</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.compensation)}
					onClick={() => goToVendorsPageAndScroll(CasesList.compensation)}
					className={styles.navigation_button}
				>
					<CompensationIcon className={`${styles.navigation_icon_white}`} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Compensation</p>
				</Button>
				<Button
					onMouseEnter={() => setActiveCase(CasesList.analytics)}
					onClick={() => goToVendorsPageAndScroll("analytics")}
					className={styles.navigation_button}
				>
					<ChartIcon className={styles.navigation_icon} />
					<p className={`${styles.title} fs16 fw500 lh20`}>Analytics</p>
				</Button>
			</div>

			<div className={styles.content_block}>
				{caseView}
			</div>
		</div>
	)
}

export default SolutionsDropdown