// for dev
// export const links = {
// 	vendors: "https://dev-vendors.techcore.io/",
// 	personal: "https://dev-personal.techcore.io/",
// }

// for prod
export const links = {
	vendors: "https://app.techcore.io/",
	personal: "https://personal.techcore.io/",
};
export const cvManagementApi =
	"https://rehhpid8d1.execute-api.us-west-1.amazonaws.com/prod"; // Frankfurt;
export const serverApi =
	"https://r04qbfgina.execute-api.us-west-1.amazonaws.com/prod";
