import styles from "./info-block.module.css"

/**
	@param {string?} align left - position the image on the left, the text on the right
	@param {string?} align right - position the image on the right, the text on the left
*/

const InfoBlock = ({
	imgPath,
	blockStyles,
	secondImgPath,
	prefixTitleText,
	titleIcon,
	descriptionText,
	align = "left",
	secondImgStyles
}) => {
	return (
		<div className={`${styles.info_block} ${styles[align]}`} style={blockStyles}>
			<div className={styles.img_block}>
				<img className={styles.screenshot} src={imgPath} alt="screenshot-icon" />
				{secondImgPath && (
					<img className={styles.second_screenshot} src={secondImgPath} alt="second-screenshot-icon" style={secondImgStyles} />
				)}
			</div>

			<div className={styles.title_block}>
				<h3 className={`${styles.title} fs36 fw500 lh44`}>
					{titleIcon && titleIcon}
					{prefixTitleText}
				</h3>

				<p className={`${styles.description} main-gray fs18 fw400 lh26`}>{descriptionText}</p>
			</div>
		</div>
	);
};

export default InfoBlock;