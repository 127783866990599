import { Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import LandingCard from "./card/LandingCard";
import styles from "./PricingPage.module.css";
import { planNames, plansDescription } from "../../../service/utils";
import i18next from "i18next";
import { links } from "../../shared/constants/constants";
const { t } = i18next;


const plans = Object.freeze({
	free: { name: "free", title: t("shared.plans.free-label"), price: 0 },
	standard: { name: "standard", title: t("shared.plans.standard-label"), price: 5 },
	pro: { name: "pro", title: t("shared.plans.pro-label"), price: 10 },
	enterprise: { name: "enterprise", title: t("shared.plans.enterprise-label"), price: null }
});

const PricingPage = () => {
	// const { t } = useTranslation();
	const [isMonthlyPeriodSelected, setIsMonthlyPeriodSelected] = useState(true);
	const [selectedCard, setSelectedCard] = useState(plans.free);
	const [usersAmount, setUsersAmount] = useState(10);

	useEffect(() => {
		if (isMonthlyPeriodSelected) {
			setSelectedCard(plans.free);
		} else {
			setSelectedCard(plans.standard);

		}
	}, [isMonthlyPeriodSelected]);


	const handleChangeUsersAmount = (amount) => {
		setUsersAmount(amount);

		if (amount <= 10) {
			return setSelectedCard(plans.free);
		}

		if (amount >= 11) {
			return setSelectedCard(plans.standard);
		}

		// if (amount > 150) {
		// 	return setSelectedCard(plans.pro);
		// }
	};


	return (
		<div className={styles.pricing_page}>
			<div className={styles.background}>
				<div className={`${styles.content} container`}>
					<h1 className={`${styles.page_title}`}>{t("landing-page.pricing-label")}</h1>

					<div className={styles.amount_block}>
						<p className={`${styles.title} fs20 fw400 lh24`}>
							{t("landing-page.pricing.how-many-users-text")}
						</p>
						<Input value={usersAmount} onChange={(e) => handleChangeUsersAmount(e.target.value)} className={styles.amount_input} />
					</div>

					<div className={styles.discount_wrapper}>
						<div className={styles.discount_info}>
							<p>
								{t("landing-page.pricing.save-up-text")}
							</p>
						</div>
						<div className={styles.period_block}>
							<p className={`${styles.period_name} ${styles.annually} ${!isMonthlyPeriodSelected && styles.selected} fs20 fw400 lh16`}>{t("shared.annually-label")}</p>
							<Switch onChange={setIsMonthlyPeriodSelected} checked={isMonthlyPeriodSelected} className={styles.switch} />
							<p className={`${styles.period_name} ${styles.monthly} ${isMonthlyPeriodSelected && styles.selected} fs20 fw400 lh16`}>{t("shared.monthly-label")}</p>
						</div>

					</div>


					<div className={styles.cards_grid}>
						<div className={styles.cards_block}>
							<div className={`${styles.card} ${selectedCard === plans.free && styles.active} ${((selectedCard === plans.free) && !isMonthlyPeriodSelected) && styles.disabled}`}>
								<LandingCard
									isActive={selectedCard === plans.free}
									title={plans.free.title}
									price={plans.free.price}
									isDisabled={!isMonthlyPeriodSelected}
									info1={t("shared.always-free-for-10-label")}
									info2={t("shared.monthly-subscriptions-only-label")}
									buttonText={t("shared.start-label")}
									onButtonClick={() => window.open(`${links.vendors}sign-up?payment-type=monthly&payment-plan=${plans.free.title.toLowerCase()}`)}
									features={plansDescription[planNames.free]}
								/>
							</div>

							<div className={`${styles.card} ${selectedCard === plans.standard && styles.active}`}>
								<LandingCard
									isActive={selectedCard === plans.standard}
									title={plans.standard.title}
									price={(isMonthlyPeriodSelected && plans.standard.price) || (plans.standard.price * usersAmount * 0.8)}
									info1={(isMonthlyPeriodSelected && t("landing-page.pricing.per-user-estimated-label")) || t("shared.per-year-label")}
									info2={isMonthlyPeriodSelected && t("shared.per-month-pricing-label", { totalPrice: `$${plans.standard.price * usersAmount}` })}
									buttonText={t("shared.start-label")}
									onButtonClick={() => window.open(`${links.vendors}sign-up?payment-type=${isMonthlyPeriodSelected ? "monthly" : "annually"}&payment-plan=${plans.standard.title.toLowerCase()}`)}
									features={plansDescription[planNames.standard]}
								/>
							</div>

							<div className={`${styles.card} ${selectedCard === plans.pro && styles.active}`}>
								<LandingCard
									isActive={selectedCard === plans.pro}
									title={plans.pro.title}
									price={(isMonthlyPeriodSelected && plans.pro.price) || (plans.pro.price * usersAmount * 0.8)}
									info1={(isMonthlyPeriodSelected && t("landing-page.pricing.per-user-estimated-label")) || t("shared.per-year-label")}
									info2={isMonthlyPeriodSelected && t("shared.per-month-pricing-label", { totalPrice: `$${plans.pro.price * usersAmount}` })}
									buttonText={t("shared.start-label")}
									onButtonClick={() => window.open(`${links.vendors}sign-up?payment-type=${isMonthlyPeriodSelected ? "monthly" : "annually"}&payment-plan=${plans.pro.title.toLowerCase()}`)}
									features={plansDescription[planNames.pro]}
								/>
							</div>

							{/* <div className={`${styles.card} ${selectedCard === plans.enterprise && styles.active}`}>
                            <Card
                                isActive={selectedCard === plans.enterprise}
                                title="Enterprise"
                                link="/"
                                linkText="Billed annually"
                                linkInfo="Switch the Billing cycle to Annual to view Enterprise pricing"
                                buttonText="Contact Sales"
                                features={[
                                    "Unlimited sites",
                                    "Centralized security controls",
                                    "Centralized user subscriptions",
                                    "24/7 Enterprise support"
                                ]}
                            />
                        </div> */}
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default PricingPage;