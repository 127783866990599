import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import styles from "./ant-spin.module.css"

const AntSpin = ({ size = 50 }) => (
	<Spin
		className={styles.spin}
		indicator={(
			<LoadingOutlined style={{ fontSize: size }} spin />
		)}
	/>
)

export default AntSpin