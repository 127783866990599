import { useEffect, useRef } from "react";
import CompanySearchBlock from "../shared/company-search-block/company-search-block";
import TopMenu from "../shared/top-menu/top-menu"
import InfoBlock from "./components/info-block/info-block"
import PopularCategories from "./components/popular-categories/popular-categories";
import styles from "./main-page.module.css"
import AIBlock from "../ai-components/AIBlock";
import FooterComponent from "../footer/FooterComponent";
import HelpBlock from "../help-block/HelpBlock";
import FooterGroup from "../footer/FooterGroup";

const MainPage = () => {
	const mainRef = useRef(null)

	const scrollToBlock = () => {
		mainRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	// useEffect(() => {
	// 	if (mainRef.current) {
	// 		scrollToBlock();
	// 	}
	// }, []);
	return (
		<div className={styles.main_page} ref={mainRef}>
			{/* <TopMenu
				hasBackground={false}
			/> */}
			<div className={styles.background}>

				{/* <div className={`${styles.content} container`}>
					<div className={styles.main_block}>
						<div className={styles.title_block}>
							<h1 className={`${styles.title} fs48 fw500 lh58`}>
								All-in-one Platform <br />
								connecting<br />
								<span className="main-blue">Business</span>,<br />
								<span className="main-blue">IT Vendors</span> and<br />
								<span className="main-blue">Individual talents</span>
							</h1>
							<p className={`${styles.description} main-gray fs18 fw400 lh26`}>
								Specifically designed for the needs of your business, you can empower your people to shine, make the most of your resources and delight your clients.
							</p>
						</div>

						<div className={styles.img_block}>
							<img src="/img/main-page-illustration.svg" alt="illustration" />
						</div>
					</div>
				</div>*/}
				<div className={styles.find_company_block}>
					<div>
						<CompanySearchBlock />
					</div>
				</div>
				<AIBlock />
			</div>
			<FooterGroup />
		</div>
	)
}

export default MainPage