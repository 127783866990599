import React, { useState } from 'react'
import { ReactComponent as ProductCompany } from "../../../../shared/img/product-company.svg"
import { ReactComponent as ServiceCompany } from "../../../../shared/img/service-company.svg"

import styles from "./index.module.css"
import { links } from '../../../constants/constants'

const companyTypes = {
	SERVICE: 0,
	PRODUCT: 1
};

const Card = ({ title, descr, icon, onClick, isCurrent, type }) => {
	return (
		<button className={styles.card} onClick={() => onClick(type)} style={{ borderColor: isCurrent && "#00A5FF" }}>
			<div className={styles.card_header}>
				{icon}
				<p className={styles.card_title}>{title}</p>
			</div>
			<p className={styles.card_descr}>{descr}</p>
		</button>
	)
}

const JoinModal = () => {
	const [currentType, setCurrentType] = useState(companyTypes.SERVICE)

	const handleTypes = (type) => {
		setCurrentType(type)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.cards_wrapper}>
				<Card
					title="Product Company"
					descr="Early-stage startup, scaling company, modern enterprise."
					// icon={<ProductCompany />}
					isCurrent={currentType === companyTypes.PRODUCT}
					onClick={handleTypes}
					type={companyTypes.PRODUCT}
				/>
				<Card
					title="Service Provider"
					descr="Outsourcing company, staff augmentation company, digital agency."
					// icon={<ServiceCompany />}
					isCurrent={currentType === companyTypes.SERVICE}
					onClick={handleTypes}
					type={companyTypes.SERVICE}
				/>
			</div>
			<a
				href={`${links.vendors}sign-up?company-type=${currentType}`}
				style={{ width: "100%" }}
				className={"ok-button"}
			>
				Continue
			</a>
			<p className={styles.footer_text}>Already have an account? <a href={`${links.vendors}sign-in`}>Sign In</a></p>
		</div>
	)
}

export default JoinModal